import { IUserSettings } from "domain/Settings/IUserSettings";
import { PriceType } from "utils/enums/PriceType";
import { IPrice } from "domain/Product/IPrice";
import { LogManager } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PriceSetting } from "utils/enums/PriceSetting";
import { IStockItem } from "domain/Product/IStockItem";
import { AppConfig } from "app-config";
import { IProduct } from "domain/Product/IProduct";

export const log = LogManager.getLogger("app.utils.helpers.price");

export class PriceHelper {
  public static formatProductsPrices(
    products: IProduct[],
    settings: IUserSettings,
    i18n: I18N,
    displayWholesaleWithoutVat = false
  ) {
    if (products == null || products.length == 0) return;

    products.forEach((product) => {
      product.displayPrice = this.getDisplayPrice(
        product.prices,
        settings,
        true
      );
      this.formatPrices(
        product.prices, 
        i18n, 
        settings
      );
      this.formatStockPrices(
        product.stock,
        settings,
        i18n,
        displayWholesaleWithoutVat
      );
      this.formatStockPrices(
        product.externalStock,
        settings,
        i18n,
        displayWholesaleWithoutVat
      );
    });
  }

  public static formatStockPrices(
    stock: IStockItem[],
    settings: IUserSettings,
    i18n: I18N,
    displayWholesaleWithoutVat = false
  ) {
    if (stock == null || stock.length == 0) return;

    stock.forEach((stockItem) => {
      PriceHelper.formatPrices(stockItem.prices, i18n, settings);

      stockItem.displayPrice = PriceHelper.getDisplayPrice(
        stockItem.prices,
        settings,
        displayWholesaleWithoutVat
      );
    });
  }

  public static formatPrices(prices: IPrice[], i18n: I18N, settings: IUserSettings) {
    if (prices == null || prices.length == 0) return null;

    prices.forEach(
      price => {
        price.name = PriceHelper.translatePriceName(price, i18n);
        price.displayAmount = PriceHelper.getPriceVatFromSettings(price, settings)
      }
    );
  }

  public static translatePriceName(price: IPrice, i18n: I18N): string {
    // Translate price names
    switch (price.type) {
      case PriceType.Wholesale:
        return i18n.tr("components.price.type.wholesale");
      case PriceType.Retail:
        return i18n.tr("components.price.type.retail");
      case PriceType.Discount:
        return i18n.tr("components.price.type.discount.regular");
      case PriceType.AmountDiscount:
        return i18n.tr("components.price.type.discount.amount");
      case PriceType.TimeDiscount:
        return i18n.tr("components.price.type.discount.time");
      case PriceType.UserGroupTimeDiscount:
        return i18n.tr("components.price.type.discount.usergroup-time");
      case PriceType.AmountDiscountList:
        return i18n.tr("components.price.type.discount.number", {
          amount: price.quantity,
        });
      default:
        return price.name ?? "";
    }
  }

  public static getDisplayPrice(
    prices: IPrice[],
    settings: IUserSettings,
    displayWholesaleWithoutVat: boolean
  ): number | undefined {
    if (settings.priceSetting == PriceSetting.None) return;

    const priceType =
      settings.priceSetting == PriceSetting.Retail &&
      !displayWholesaleWithoutVat
        ? PriceType.Retail
        : PriceType.Wholesale;
    const price = prices.find((price) => price.type == priceType);

    return displayWholesaleWithoutVat
      ? PriceHelper.getPrice(price, false)
      : PriceHelper.getPriceVatFromSettings(price, settings);
  }

  public static getPriceVatFromSettings(
    price: IPrice | undefined,
    settings: IUserSettings
  ) {
    if (price?.withoutVat == undefined) return;

    const vatIncluded =
        price.type == PriceType.Retail
          ? settings.vatIncludedInRetail
          : settings.vatIncludedInWholesale;

    return PriceHelper.getPrice(price, vatIncluded);
  }

  public static getPrice(
    price?: IPrice,
    vatIncluded?: boolean
  ) {
    if (price?.withoutVat == undefined) return;

    return vatIncluded
      ? price.withoutVat * (1 + AppConfig.vatRate)
      : price.withoutVat;
  }
}
